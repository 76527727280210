@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,600;0,800;1,300&display=swap');

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", 
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#components-layout-demo-responsive .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.2);
}

.site-layout-sub-header-background {
  background: #fff;
}

.site-layout-background {
  background: #fff;
}

/* 

::-webkit-scrollbar {
  width: 6px;
  height: 2px;
  border-radius: 2px;
  border: solid 1px rgba(213, 213, 213, 0.2);
}

::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.6);
  width: 20px;
}

::-webkit-scrollbar-thumb {
  background-color: #6855f6;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgb(155, 79, 247);
}

*/