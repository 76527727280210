/* styles.css */

@media (max-width: 767px) {
    .mt-top {
        margin-top: 30px;
    }
}

@media (min-width:768px) {
    .mt-top {
        margin-top: 50px;
    }
}

.flex-d {
    width: 40%;
}


@media (min-width: 467px) and (max-width: 1023px) {
    .flex-d {
        width: 50%;
    }
}

@media (min-width: 1024px) and (max-width: 1279px) {
    .flex-d {
        width: 60%;
    }
}

@media (min-width: 1280px) {
    .flex-d {
        width: 70%;
    }
}



.flex-d2 {
    width: 60%;
}


@media (min-width: 467px) and (max-width: 1023px) {
    .flex-d2 {
        width: 50%;
    }
}

@media (min-width: 1024px) and (max-width: 1279px) {
    .flex-d2 {
        width: 40%;
    }
}

@media (min-width: 1280px) {
    .flex-d2 {
        width: 30%;
    }
}




.dynamic-image {
    height: 60px;
    margin-top: 30px;
}

@media (min-width: 467px) and (max-width: 768px) {
    .dynamic-image {
        height: 70px;
        margin-top: 30px;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .dynamic-image {
        height: 80px;
    }
}

@media (min-width: 1024px) and (max-width: 1279px) {
    .dynamic-image {
        height: 90px;
    }
}

@media (min-width: 1280px) {
    .dynamic-image {
        height: 100px;
    }
}

.hero-image {
    height: 140px;
}

@media (min-width: 467px) and (max-width: 768px) {
    .hero-image {
        height: 160px;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .hero-image {
        height: 180px;
    }
}

@media (min-width: 1024px) and (max-width: 1279px) {
    .hero-image {
        height: 220px;
    }
}

@media (min-width: 1280px) {
    .hero-image {
        height: 250px;
    }
}

@media (min-width: 800px) {
    .leftm {
        margin-left: 40px;
    }
}

/* .img-container {
    justify-content: "center";
    width: 100%;
    display: "flex";
    justify-items: "center";
    height: "100%",
} */

@media (max-width: 767px) {
    .img-container {
        flex-direction: column;
    }
}

@media (min-width:768px) {
    .img-container {
        flex-direction: row;
    }
}

@media (max-width: 767px) {
    .border-container {
        flex-direction: column;
    }
}

@media (min-width:768px) {
    .border-container {
        flex-direction: row;
    }
}

.circ {
    height: 100px;
}

@media (min-width: 470px) {
    .circ {
        height: 120px;
    }
}

@media (min-width: 670px) {
    .circ {
        height: 130px;
    }
}

@media (min-width: 1200px) {
    .circ {
        height: 150px;
    }
}

.grid-container {
    grid-template-columns: repeat(1, minmax(0px, 1fr));
}

@media (min-width: 470px) {
    .grid-container {
        grid-template-columns: repeat(1, minmax(250px, 1fr));
    }
}

@media (min-width: 670px) {
    .grid-container {
        grid-template-columns: repeat(1, minmax(300px, 1fr));
    }
}

@media (min-width: 870px) {
    .grid-container {
        grid-template-columns: repeat(2, minmax(400px, 1fr));
    }
}

@media (min-width: 1200px) {
    .grid-container {
        grid-template-columns: repeat(2, minmax(500px, 1fr));
    }
}

.heading {
    font-size: clamp(16px, calc(20px + (28 - 20) * (100vw - 768px)/(1920 - 768)), 44px);
    ;
}

.heading-hero {
    font-size: clamp(16px, calc(16px + (24 - 20) * (100vw - 768px)/(1920 - 768)), 40px);
    ;
}


.heading-2 {
    font-size: clamp(24px, calc(32px + (36 - 20) * (100vw - 768px)/(1920 - 768)), 56px);
    ;
}

.heading-3 {
    font-size: clamp(16px, calc(16px + (24 - 20) * (100vw - 768px)/(1920 - 768)), 28px);
    ;
}


@media (max-width: 767px) {
    .pdtop {
        margin-top: 30px;
    }
}

@media (min-width:768px) {
    .pdtop {
        margin-top: 0px;
    }
}

@media (max-width: 767px) {
    .trust-container {
        margin-top: 50px;
    }
}

@media (min-width:768px) {
    .trust-container {
        margin-top: 40px;
    }
}

@media (min-width:916px) {
    .trust-container {
        margin-top: 60px;
    }
}


@media (max-width: 767px) {
    .trust-inner {
        padding: 5px;
    }
}

@media (min-width:768px) {
    .trust-inner {
        padding: 15px;
    }
}

@media (min-width:916px) {
    .trust-inner {
        padding: 25px;
        min-height: 70vh;
    }

}


@media (max-width: 767px) {
    .trust-image {
        padding: 5px;
    }
}

@media (min-width:768px) {
    .trust-image {
        padding: 15px;
    }
}

@media (min-width:916px) {
    .trust-image {
        padding: 25px;
    }
}


@media (max-width: 467px) {
    .footer-container {
        margin-top: 15px;
    }
}

@media (max-width: 767px) {
    .footer-container {
        margin-top: 35px;
    }
}

@media (min-width:768px) {
    .footer-container {
        margin-top: 55px;
    }
}

@media (min-width:916px) {
    .footer-container {
        margin-top: 75px;
    }
}


@media (max-width: 467px) {
    .logo-container {
        width: "20px";
        height: "20px";
    }
}

@media (min-width: 467px) and (max-width: 1023px) {
    .logo-container {
        width: "25px";
        height: "25px";
    }
}

@media (min-width: 1024px) and (max-width: 1279px) {
    .logo-container {
        width: "30px";
        height: "30px";
    }
}

@media (min-width: 1280px) {
    .logo-container {
        width: "35px";
        height: "35px";
    }
}