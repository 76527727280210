.descriptionhalf {
  display: block;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 160px;
  transition: width 2s, height 4s;
}

.descriptionfull {
  display: block;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: auto;
  transition: width 2s, height 4s;
}
.listHead {
  z-index: 300;
  /* background-color: rgb(126, 95, 211); */
  padding: 20px;
  font-size: 19px;
  font-weight: bold;
  font-family: "Times New Roman", Times, serif;
}
.lstitem {
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
  border-bottom: solid 1px gray;
}
.lstitem:hover {
  color: rgb(50, 60, 211);
  font-size: 17px;
}
