/* MessageHeader.css */
.message-header {
    display: flex;
    align-items: center;
  }
  
  .message-header.with-padding.is-forwarded {
    padding: 8px 0 2px 12px;
  }
  
  .message-header.with-padding:not(.is-replied):not(.has-message-body) {
    padding: 8px 0 0 12px;
  }
  
  .message-header.with-padding:not(.is-replied):not(.has-message-body).with-media-attachment {
    padding: 8px 0 8px 12px;
  }
  
  .message-header .message-owner {
    margin: 0 12px 4px 0;
    white-space: nowrap;
    color: #5159F6;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .message-header .message-owner.rtl-direction {
    margin-left: auto;
    margin-right: 0;
  }
  
  .message-header .message-owner.clickable {
    cursor: pointer;
  }